import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'

import { onChange } from '#helper/onChange'
import { attributesSorting } from '#helper/showData'

import Input from '#comp/Custom/Input'

import Select from './Select.jsx'

/**
 * @class
 * @classdesc - Display a Select component inside editable devices.
 * @example
 * <SingleAttribute input={input} inputs={inputs} />
 */
class SingleAttribute extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {Object} input - The required input object.
	 * @property {Array} inputs - The required array of inputs.
	 */
	static propTypes = {
		input: PropTypes.object.isRequired,
		inputs: PropTypes.array.isRequired,
	}
	static defaultProps = {
		input: {},
		inputs: [],
	}

	render() {
		const { updateParent, appControlled } = this.context
		const { input, inputs } = this.props

		if (appControlled.find((appC) => appC.name === input.name)?.catalogue) {
			return <Select input={input} inputs={inputs} />
		}

		return (
			<Input
				name={input.name}
				onChange={(e) => {
					onChange(e, (keyValue) => updateParent(keyValue))
				}}
				value={input.value === null ? '' : input.value}
			>
				{input.displayname}
			</Input>
		)
	}
}

/**
 * @class
 * @classdesc - Handle the inputs when the edit mode is selected.
 * @example
 * <Inputs
 * appControlled={appControlled}
 * updateParent={this.updateInputs}
 * inputs={inputs}
 * devices={devices}
 * device={device}
 * />
 */
export default class Inputs extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {Array} appControlled - An array of values controlled by the application.
	 * @property {Function} updateParent - A function to update the parent component or context.
	 * @property {Array} inputs - An array of input values.
	 * @property {Array} devices - An array of devices.
	 * @property {Object} device - An object representing a device.
	 */
	static propTypes = {
		appControlled: PropTypes.array.isRequired,
		updateParent: PropTypes.func.isRequired,
		inputs: PropTypes.array.isRequired,
		devices: PropTypes.array.isRequired,
		device: PropTypes.object.isRequired,
	}
	static defaultProps = {
		appControlled: [],
		updateParent: () => {},
		inputs: [],
		devices: [],
		device: {},
	}

	render() {
		const { t, catalogue } = this.context
		const { appControlled, updateParent, inputs, devices, device } =
			this.props

		return (
			<Context.Provider
				value={{
					t,
					catalogue,
					updateParent,
					inputs,
					devices,
					device,
					appControlled,
				}}
			>
				<div className="flex space-y-4 flex-col">
					{attributesSorting(inputs, t).map((input, i) => (
						<div key={i + '_Inputs'}>
							<SingleAttribute input={input} inputs={inputs} />
						</div>
					))}
				</div>
			</Context.Provider>
		)
	}
}
