import React, { Component } from 'react'

import {} from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import {
	faWifi,
	faSnowflakes,
	faBolt,
	faMicrochip,
	faMouseField,
	faPersonWalking,
	faTemperatureThreeQuarters,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// DOKU:

export default class IconsDeviceType extends Component {
	icon = (devicetype) => {
		switch (devicetype) {
			// Gateway
			case 'gateway':
			case 'Gateway':
				return { icon: faWifi }

			// Temperature
			case 'Milesight EM500-PT100':
			case 'Milesight EM300-TH':
			case 'Senlab TEM-LAB-14NS':
				return { icon: faTemperatureThreeQuarters }

			// Mouse Trap
			case 'Xignal Rattrap':
				return { icon: faMouseField }

			// Rat Trap
			case 'Xignal Mousetrap':
				return { custom: 'fa-mouse-front' }

			// Snow
			case 'Milesight UC501 Snow':
				return { icon: faSnowflakes }

			// Electricity Meter
			case 'HarvyLR-36':
			case 'MCF LW12PLG':
				return { icon: faBolt }

			// Motion
			case 'Xignal Motion Sensor':
				return { icon: faPersonWalking }

			// IO-Boxes
			case 'MCF LW13IO':
				return { icon: faMicrochip }

			default:
				return { icon: faCircle }
		}
	}

	render() {
		const { type, beat, className } = this.props
		if (this.icon(type).custom) {
			return (
				<i
					className={
						'fa-kit ' +
						this.icon(type).custom +
						' ' +
						(beat ? 'fa-beat' : '') +
						' fa-lg ' +
						className
					}
				></i>
			)
		} else {
			return (
				<FontAwesomeIcon
					icon={this.icon(type).icon}
					beat={beat}
					className={className}
				/>
			)
		}
	}
}
