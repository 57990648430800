import React, { Component } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { getLS } from '#helper/localStorage'
import filter from '#helper/Fetch API/filter'
import { defaultHeader } from '#helper/Fetch API/request'

import Wrap from '#comp/Wrapper/Wrap'
import NotFound from '#comp/Wrapper/NotFound'
import ImageModal from '#comp/Custom/Modal/ImageModal'

import Tenant from './Tenant'
import DeviceRouter from './Devices/Router'

/**
 * @class
 * @classdesc Router inside the instance for a single tenant, also fetching a bunch of stuff.
 * @example
 * <TenantRouter />
 */
export default class TenantRouter extends Component {
	static contextType = Context

	state = {
		pictureLoading: false,
		tenants: null,
		countTenants: null,
		notFound: false,
		showPicture: false,
		selectedPhoto: {},
		poolTenant: null,
	}

	getRecentTenants =
		JSON.parse(getLS('recent_' + this.context.instance.shortLink)) || []

	/**
	 * Retrieves a list of tenants based on the provided filter and sets the state of the component.
	 *
	 * @async
	 * @param {String} input - The search term to filter tenants by.
	 * @param {String} errorcode - Error code when the request failed.
	 * @returns {Promise<Array<Object>>} - An array of tenant objects that match the search term.
	 */
	fetchTenants = async (input, errorcode) => {
		const tenantsRequest = await this.context.apiFetch(
			`${this.context.instance.api}/Tenant/getFiltered`,
			defaultHeader(
				this.context.auth.access_token,
				'POST',
				filter(input, 0)
			)
		)
		if (tenantsRequest.logout) return

		if (tenantsRequest.ok) {
			const data = tenantsRequest.data
			if (data.length === 0) {
				checkToast(this.context.t, 12001)
			} else {
				if (data.total_count > data.pageSize) {
					checkToast(this.context.t, 12006)
				}
				var tenants = data.tenants
			}
			this.setState({ tenants })
		} else {
			checkToast(this.context.t, errorcode)
		}
	}

	/**
	 * Change "notFound" state.
	 *
	 * @returns {void}
	 */
	changeNotFound = () => {
		this.setState({ notFound: !this.state.notFound })
	}

	/**
	 * Retrieves the tenant object for the specified ID and sets the state of the component.
	 * Also loads the pool tenant.
	 *
	 * @async
	 * @param {Number} id - The ID of the tenant to retrieve.
	 * @returns {Object|null} The tenant object for the specified ID.
	 */
	fetchOneTenant = async (id) => {
		const { t, instance, auth, apiFetch } = this.context

		const getOneTenantRequest = await apiFetch(
			`${instance.api}/Tenant/${id}`,
			auth.access_token
		)
		if (getOneTenantRequest.logout) return null

		if (getOneTenantRequest.ok) {
			const tenantArray = [getOneTenantRequest.data]
			const tenant = tenantArray?.find((i) => i.id.toString() === id)

			const getPoolTenantRequest = await apiFetch(
				`${instance.api}/Tenant/${process.env.REACT_APP_POOL_TENANT}`,
				auth.access_token
			)

			if (!getPoolTenantRequest.ok) {
				this.changeNotFound()
				checkToast(t, 12005)
				return null
			}

			this.setState({
				tenants: tenantArray,
				poolTenant: getPoolTenantRequest.data,
			})

			return (
				tenant ||
				this.getRecentTenants.find((i) => i.id.toString() === id)
			)
		} else {
			this.changeNotFound()
			checkToast(t, 12005)
			return null
		}
	}

	/**
	 * Retrieves the attachment.
	 *
	 * @async
	 * @param {Number} id - The ID of the device to retrieve.
	 * @returns {void}
	 */
	fetchPicture = async (id, serial) => {
		this.setState({ pictureLoading: true })
		const getPictureRequest = await this.context.apiFetch(
			`${this.context.instance.api}/Device/${id}/attachment`,
			this.context.auth.access_token
		)
		if (getPictureRequest.logout) {
			this.setState({ pictureLoading: false })
			return
		}

		if (!getPictureRequest.ok) {
			this.setState({ pictureLoading: false })
			checkToast(this.context.t, 17001)
			return
		}

		var selectedPhoto = getPictureRequest.data
		if (selectedPhoto) {
			if (selectedPhoto.length === 0 || selectedPhoto[0].data === '') {
				this.setState({ pictureLoading: false })
				checkToast(this.context.t, 17002)
			} else {
				selectedPhoto[0].serial = serial
				this.setState({
					selectedPhoto: selectedPhoto[0],
					showPicture: true,
					pictureLoading: false,
				})
			}
		}
	}

	/**
	 * Close the picture.
	 *
	 * @returns {void}
	 */
	closePicture = () => {
		this.setState({ selectedPhoto: {}, showPicture: false })
	}

	render() {
		const {
			notFound,
			showPicture,
			tenants,
			selectedPhoto,
			pictureLoading,
			poolTenant,
		} = this.state

		// If no tenant was found.
		if (notFound) {
			return <NotFound changeNotFound={this.changeNotFound} />
		}

		return (
			<Context.Provider
				value={{
					...this.context,
					recentTenants: this.getRecentTenants,
					tenants,
					fetchTenants: this.fetchTenants,
					fetchOneTenant: this.fetchOneTenant,
					fetchPicture: this.fetchPicture,
					pictureLoading,
					poolTenant,
				}}
			>
				<Routes>
					<Route path="/" element={<Wrap routeEl={Tenant} />} />
					<Route
						path=":tenantId/*"
						element={<Wrap routeEl={DeviceRouter} />}
					/>
					<Route path="*" element={<Navigate to="./" replace />} />
				</Routes>
				{showPicture && (
					<div>
						<ImageModal
							selectedPhoto={selectedPhoto}
							show={showPicture}
							onClose={this.closePicture.bind(this)}
						/>
					</div>
				)}
			</Context.Provider>
		)
	}
}