import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'
import checkToast from '#toast'

import { onChange } from '#helper/onChange'

import Input from '#comp/Custom/Input'
import Logo from '#comp/Wrapper/Logo'

import { Button } from 'flowbite-react'

import Footer from '../Structure/Footer'

/**
 * @class
 * @classdesc - Inputs where the User can login.
 * @example
 * <LoginFrom
 * username={username}
 * password={password}
 * t={t}
 * onChangeParent={this.handleChangeInput}
 * />
 */
class LoginFrom extends Component {
	/**
	 * @typedef {Object} PropTypes
	 * @property {String} username - The username for authentication.
	 * @property {String} password - The password for authentication.
	 * @property {Function} t - The translation function.
	 * @property {Function} onChangeParent - The callback function for parent changes.
	 */
	static propTypes = {
		username: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		onChangeParent: PropTypes.func.isRequired,
	}
	static defaultProps = {
		username: '',
		password: '',
		t: () => {},
		onChangeParent: () => {},
	}

	render() {
		const { username, password, t, onChangeParent } = this.props

		return (
			<>
				<Input
					name="username"
					onChange={onChangeParent.bind(this)}
					value={username}
					required={true}
					type="text"
				>
					{t('login.login.email')}
				</Input>
				<Input
					name="password"
					onChange={onChangeParent.bind(this)}
					value={password}
					type="password"
					required={true}
				>
					{t('login.login.password')}
				</Input>
			</>
		)
	}
}

/**
 * @class
 * @classdesc - Handles the login process for the application, including authentication and password recovery.
 * @example
 * <Login />
 */
export default class Login extends Component {
	static contextType = Context

	state = {
		username: '',
		password: '',
		loading: false,
		forgot: false,
	}

	/**
	 * Handles the form submission for logging in and password recovery. If the `forgot` state is true, sends a password recovery request. Otherwise, sends a login request.
	 * @async
	 * @param {Event} e
	 * @returns {void}
	 */
	handleLoginSubmit = async (e) => {
		e.preventDefault()

		const { forgot, username, password } = this.state
		const { t, instance, login, apiFetch } = this.context

		if (forgot) {
			if (!username) {
				checkToast(t, 11004)
				return
			}
			// TODO: Create one Page inside the app to reset your password with the baseURL
			const forgotRequest = await apiFetch(
				`${instance.api}/Authentication/ResetPassword?userEmail=${username}&resetPasswordBaseUrl=https://client-smartsignal.iot.kapschcloud.net`,
				{ method: 'POST' }
			)
			if (forgotRequest.ok) {
				checkToast(t, 11101)
			} else if (!forgotRequest.ok) {
				if (forgotRequest.data.error === 'not_found') {
					checkToast(t, 11007)
				} else {
					checkToast(t, 11008)
				}
			} else {
				checkToast(t, 11101)
			}
		} else {
			login(username, password)
		}
	}

	/**
	 * Toggles the 'forgot' state between true and false.
	 * @returns {void}
	 */
	handleForgotClick = () => {
		this.setState((prevState) => ({ forgot: !prevState.forgot }))
	}

	/**
	 * Handles input changes and updates the component state.
	 * @param {String} e
	 * @returns {void}
	 */
	handleChangeInput = (e) => {
		onChange(e, (data) => this.setState(data))
	}

	render() {
		const { instance, t } = this.context
		const { forgot, username, password } = this.state

		return (
			<>
				<div className="flex flex-col gap-8">
					<div className="h-24 w-auto">
						<Logo
							instance={instance}
							className="max-h-full mx-auto"
						/>
					</div>
					<form
						onSubmit={this.handleLoginSubmit}
						className="flex flex-col gap-4"
					>
						{forgot ? (
							<Input
								name="username"
								onChange={this.handleChangeInput}
								value={username}
								required={true}
								type="text"
							>
								{t('login.login.email')}
							</Input>
						) : (
							<LoginFrom
								username={username}
								password={password}
								t={t}
								onChangeParent={this.handleChangeInput}
							/>
						)}
						<Button type="submit">
							{forgot
								? t('login.forgot.submit')
								: t('login.login.submit')}
						</Button>
					</form>
					<button
						onClick={this.handleForgotClick}
						className="text-center underline"
					>
						{forgot
							? t('login.forgot.forgot')
							: t('login.login.forgot')}
					</button>
				</div>
				<div className="mt-4 pb-1">
					<Footer />
				</div>
			</>
		)
	}
}
