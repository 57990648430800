import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'
import checkToast from '#toast'

import LoadingScreen from '#comp/LoadingScreen'
import ScrollButton from '#comp/Custom/Scroll/ScrollButton'
import ScrollFooter from '#comp/Custom/Scroll/ScrollFooter'
import DeviceCard from '#comp/Custom/Devices/DeviceCard'

import { faFilter } from '@fortawesome/pro-light-svg-icons'

import SingleDevice from './SingleDevice'
import BottomButtons from './addDevice/BottomButtons'

/**
 * @class
 * @classdesc - Shows the devices page and handles reloads.
 * @example
 * <Wrap routeEl={Devices} />
 */
export default class Devices extends Component {
	static contextType = Context

	state = {
		loading: true,
		devices: [],
		currentFilter: 'all',
		specialDevices: [],
		extendedIds: [],
	}

	/**
	 * @typedef {Object} ParamsShape
	 * @property {string} tenantId - The tenantId parameter of the url.
	 * @property {...*} [otherProps] - Additional properties that may be present in the params.
	 * @typedef {Object} PropTypes
	 * @property {ParamsShape} params
	 * @property {Object} [locations]
	 * @property {ReactNode} routeEl
	 */
	static propTypes = {
		params: PropTypes.shape({
			tenantId: PropTypes.string.isRequired,
		}),
		locations: PropTypes.object,
		routeEl: PropTypes.func.isRequired,
	}
	static defaultProps = {
		params: { tenantId: '' },
	}

	/**
	 * Initiates the process of loading devices by resetting the devices array and setting loading state to true.
	 *
	 * @returns {void}
	 */
	loadDevices = () => {
		this.setState({ devices: [], loading: true })
		this.fetchDevices(this.props.params.tenantId)
	}

	/**
	 * Handles the extension of all items.
	 *
	 * @param {Boolean} extended - Indicates whether to extend (true) or collapse (false) all items.
	 * @returns {void}
	 */
	extendAll = (extended) => {
		var extendedIds = null
		if (extended) {
			extendedIds = this.state.devices.map((device) => device.id)
		} else {
			extendedIds = []
		}
		this.setState({ extendedIds })
	}

	/**
	 * Handles the extension a specific item.
	 *
	 * @param {String} id - The id of the item that should be extended or collapsed.
	 * @returns {void}
	 */
	extendCard = (id) => {
		var { extendedIds } = this.state
		if (extendedIds.includes(id)) {
			extendedIds = extendedIds.filter((myId) => myId !== id)
		} else {
			extendedIds.push(id)
		}
		this.setState({ extendedIds })
	}

	/**
	 * Adds a special device with the given ID and name to the component state.
	 *
	 * @param {String} id - The ID of the special device to add.
	 * @param {String} name - The name of the special device to add.
	 * @returns {void}
	 */
	setSpecialDevices = (id, name) => {
		var specialDevices = this.state.specialDevices
		const findDevice = specialDevices.find((d) => d.id === id)
		if (typeof findDevice === 'undefined') {
			specialDevices.push({ id, name })
			this.setState({ specialDevices })
		}
	}

	/**
	 * Fetches devices from the server based on the provided tenant ID.
	 *
	 * @async
	 * @param {string} tenantId - The tenant ID for which devices are to be fetched.
	 * @returns {void}
	 */
	fetchDevices = async (tenantId) => {
		const deviceRequest = await this.context.apiFetch(
			`${this.context.instance.api}/Device?tenantId=${tenantId}&pageSize=100&page=0`,
			this.context.auth.access_token
		)
		if (deviceRequest.logout) return

		if (!deviceRequest.ok) {
			checkToast(this.context.t, 13001)
		}

		// FUTURE: v4.0 - Filter für mehrere Optionen ermöglichen!
		var filteredGW = deviceRequest.data.devices.filter(
			(d) => d.typeId === 1
		)
		var filteredIO = deviceRequest.data.devices.filter(
			(d) => d.typeId === 13
		)
		var noGWIO = deviceRequest.data.devices
			.filter((d) => d.typeId !== 1 && d.typeId !== 2 && d.typeId !== 13)
			.sort((a, b) => {
				return a.serial - b.serial
			})

		var allDevicesIO = filteredIO.concat(noGWIO)
		var devices = filteredGW.concat(allDevicesIO)
		this.setState({ devices, loading: false })
		// FUTURE: v4.0 - Vernünftige Logik auch für andere Kunden (evtl. GW abholen über Config, wie ist das bei netmore?)
		if (deviceRequest.data.total_count > deviceRequest.data.pageSize) {
			checkToast(this.context.t, 13010)
		}
		// FUTURE: v2.4 - Paging
	}

	/**
	 * Filters devices to show only those marked as notifications.
	 *
	 * @returns {Array<Object>} - An array of devices marked as notifications.
	 */
	isNotif = () => {
		return this.state.devices.filter((device) =>
			this.state.specialDevices
				.map((device) => {
					return device.id
				})
				.includes(device.id)
		)
	}

	/**
	 * Filters devices based on a specific name in the special devices list.
	 *
	 * @param {String} name - The name to filter special devices.
	 * @returns {Array<Object>} - An array of devices with the specified name in special devices.
	 */
	isSpecial = (name) => {
		var filtered = this.state.specialDevices
			.filter((device) => device.name === name)
			.map((device) => {
				return device.id
			})

		return this.state.devices.filter((device) =>
			filtered.includes(device.id)
		)
	}

	/**
	 * Handles filtering devices based on the provided value.
	 *
	 * @param {String} value - The filter value
	 * @returns {void}
	 */
	handleFilter = (value) => {
		this.setState({ currentFilter: value, extendedIds: [] })
	}

	/**
	 * Filters and returns devices based on the current filter.
	 *
	 * @param {Array<Object>} devices - The array of devices to filter.
	 * @param {String} currentFilter - The current filter value.
	 * @returns {Array<Object>} - An array of devices based on the current filter.
	 */
	displayedDevices = (devices, currentFilter) => {
		switch (currentFilter) {
			case 'all':
				return devices

			case 'notif':
				return this.isNotif()

			// case 'objectType':
			// 	return devices

			case 'online':
				return devices.filter(
					(d) => !this.isSpecial('offline').includes(d)
				)

			case 'offline':
				return this.isSpecial('offline')

			default:
				return null
		}
	}

	/**
	 * Generates an array of objects with filter variables and corresponding devices.
	 *
	 * @param {Array<Object>} devices - The array of devices to filter.
	 * @returns {Array<Object>} - An array of objects with filter variables and corresponding devices.
	 */
	scrollButtons = (devices) => {
		return [
			{
				var: 'all',
				devices: devices,
			},
			{
				var: 'notif',
				devices: this.isNotif(),
			},
			// {
			// 	var: 'objectType',
			// 	// FUTURE: v2.4 - Open Extended Filters (ev. Modal) to select more and/or detailed filters/object types
			// 	devices: devices,
			// },
			{
				var: 'online',
				devices: devices.filter(
					(d) => !this.isSpecial('offline').includes(d)
				),
			},
			{
				var: 'offline',
				devices: this.isSpecial('offline'),
			},
		]
	}

	/**
	 * Returns the devices corresponding to the current filter from the modes array.
	 *
	 * @returns {Array<Object>} - An array of devices corresponding to the current filter.
	 */
	currentDevices = () => {
		var filter = this.state.currentFilter
		var modes = this.scrollButtons(this.state.devices)
		return modes.find((m) => m.var === filter).devices
	}

	componentDidMount = () => {
		this.loadDevices()
	}

	render() {
		const { t, tenant } = this.context
		const { loading, currentFilter, devices, extendedIds } = this.state

		if (loading) {
			return <LoadingScreen.Spinner className="mt-4" />
		}

		return (
			<div className="px-0 sm:px-5 md:px-10">
				<h2 className="text-center text-xl md:text-3xl mb-2">
					{tenant.name}
				</h2>
				<hr />
				<ScrollFooter icon={faFilter}>
					{this.scrollButtons(devices).map((btn) => (
						<Fragment key={btn.var + '_Buttons_' + currentFilter}>
							<ScrollButton
								active={currentFilter === btn.var}
								onClick={() => this.handleFilter(btn.var)}
							>
								{`${t('devices.filter.' + btn.var)} (${
									btn.devices.length
								})`}
							</ScrollButton>
						</Fragment>
					))}
				</ScrollFooter>
				<div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
					{this.currentDevices().map((device, i) => (
						<Fragment key={i + '_devices_' + currentFilter}>
							{extendedIds.includes(device.id) ? (
								<div className="col-span-2 sm:col-span-3">
									<SingleDevice
										extendCard={this.extendCard}
										device={device}
										setSpecialDevices={
											this.setSpecialDevices
										}
									/>
								</div>
							) : (
								<div onClick={() => this.extendCard(device.id)}>
									<DeviceCard
										device={device}
										setSpecialDevices={
											this.setSpecialDevices
										}
									/>
								</div>
							)}
						</Fragment>
					))}
				</div>
				{this.currentDevices().length === 0 && (
					<div className="text-center mt-4">
						{t('devices.noDevicesInList')}
					</div>
				)}
				<BottomButtons
					extendAll={this.extendAll}
					loadDevices={this.loadDevices}
				/>
			</div>
		)
	}
}
