import { toast } from 'react-toastify'

/**
 * Combine toast with success path
 *
 * @param {Function} t - Translation function
 * @param {String} path - from the codeReader or checkToast-Handler
 * @param {Object} [params] - Additionally params for translation
 * @returns {void}
 */
const success = (t, path, params) => {
	toast.success(t(`success.${path}`, params))
}
/**
 * Combine toast with error path
 *
 * @param {Function} t - Translation function
 * @param {String} path - from the codeReader or checkToast-Handler
 * @param {Object} [params] - Additionally params for translation
 * @returns {void}
 */
const error = (t, path, params) => {
	toast.error(t(`error.${path}`, params))
}

/**
 * Explanation of all error codes across the application.
 * First two digits are reserved for the topic is the error. (documentation of every topic inside of the switch)
 * The third digit is reserved, what type of toast should be displayed. (0 = error, 1 = success)
 * The last two are reserved for the specific issue in a topic.
 *
 * @param {Function} t - Translation function
 * @param {Number} code - Given Code from the application
 * @param {Object} [params] - Additionally params for translation
 * @returns {void}
 */

const codeReader = (t, code, params) => {
	switch (code) {
		// 10xxx - general errors
		case 10001:
			// IOTA-session ran out
			error(t, 'all.sessionTimeOut')
			break
		case 10002:
			// Code is NaN
			error(t, 'all.somethingWentWrong')
			break
		case 10003:
			// Wrong Digits
			error(t, 'all.wrongDigits', params)
			break
		case 10004:
			// Not yet implemented
			error(t, 'all.notYetImplemented')
			break

		// 11xxx - Login
		case 11002:
			// Invalid credentials were sent
			error(t, 'login.invalidCredentials')
			break
		case 11003:
			// No credentials was found in the input
			error(t, 'login.noCredentials')
			break
		case 11004:
			// Enter mail address for resetting password
			error(t, 'login.enterMail')
			break
		case 11005:
			// Something went wrong logging in
			error(t, 'login.noLogin')
			break
		case 11006:
			// Something went wrong logging in in NETMORE
			error(t, 'login.noNetmore')
			break
		case 11007:
			// No User was found
			error(t, 'login.noUser')
			break
		case 11008:
			// Some other error occoured on trying to reset password
			error(t, 'login.noPasswordReset')
			break
		case 11009:
			// This is an incorrect email adress
			error(t, 'login.incorrectEmailAddress')
			break
		case 11101:
			// password has been resetted Successfully
			// Successfully sent resetPassword to API
			success(t, 'login.resetPassword')
			break
		case 11102:
			// successfully logged out
			success(t, 'login.logoutSuccess')
			break

		// 12xxx - Tenants
		case 12001:
			// There was no tenants found with the given search input
			error(t, 'tenant.noTenants')
			break
		case 12002:
			// Too less charactors was entered in the Input field for searching a tenant
			error(t, 'tenant.tooLessChars', params)
			break
		case 12003:
			// There was a problem loading the tenants after searching for them
			error(t, 'tenant.searchDelayInput')
			break
		case 12004:
			// There was a problem loading the tenants automatically
			error(t, 'tenant.autoLoad')
			break
		case 12005:
			// There was a problem finding the tenant
			error(t, 'tenant.findTenant')
			break
		case 12006:
			// Not all tenants were loaded
			error(t, 'tenant.notAllTenants')
			break
		case 12007:
			// Catalogue of the tenant could not be loaded
			error(t, 'tenant.catalogue')
			break

		// 13xxx - Devices
		case 13001:
			// There was a problem loading the devices with the given tenant id
			error(t, 'devices.loadDevices')
			break
		case 13002:
			// There was a problem loading a single devices
			error(t, 'devices.loadOneDevice')
			break
		case 13003:
			// Device doesn't belong to this tenant(Id)
			error(t, 'devices.wrongTenant')
			break
		case 13004:
			// Devicetype wasn't found
			error(t, 'devices.noDeviceType')
			break
		case 13005:
			// No Device with this Edid was found
			error(t, 'devices.noDeviceWithEdid', params)
			break
		case 13006:
			// This devices is not allowed to be moved
			error(t, 'devices.bannedSearch')
			break
		case 13007:
			// An error occurred provisioning the device
			error(t, 'devices.noProvisioned')
			break
		case 13008:
			// No netmore device was found
			error(t, 'devices.noNetmoreDevice')
			break
		case 13010:
			// Not all devices were loaded
			error(t, 'devices.notAllDevices')
			break
		case 13011:
			// Not installation place found
			error(t, 'devices.noInstallationPlace')
			break
		case 13012:
			// Something went wrong loading devices with this name
			error(t, 'devices.loadDevicesWithName')
			break
		case 13101:
			// The device provisioning has been changed successfully
			success(t, 'devices.provisionedSuccessfully')
			break

		// 14xxx - Profile
		case 14001:
			// There was a problem loading the profile
			error(t, 'profile.noProfileFound')
			break

		// 15xxx - Device Attributes
		case 15002:
			// Failed to save attributes in IOTA
			error(t, 'attributes.failedSaveAttributes')
			break
		case 15003:
			// Installation Place must be set
			error(t, 'attributes.noInstallationPlace')
			break
		case 15004:
			// The device could not be moved
			error(t, 'attributes.moveDevice')
			break
		case 15005:
			// Installation Number is not available
			error(t, 'attributes.noInstallationNumber')
			break
		case 15101:
			// All attributes were saved to IOTA successfully
			success(t, 'attributes.successfullyUpdated')
			break
		case 15102:
			// The device moved successfully to new tenant
			success(t, 'attributes.moveDevice')
			break

		// 16xxx - Camera
		case 16001:
			// No Access to Cameras
			success(t, 'camera.noAccess')
			break
		case 16002:
			// Browser does not support this
			success(t, 'camera.noSupport')
			break

		// 17xxx - Photo
		case 17001:
			// Failed loading the attachment
			error(t, 'photo.loadPhoto')
			break
		case 17002:
			// There is no attachment for this device
			error(t, 'photo.noPhoto')
			break
		case 17003:
			// Failed saving the attachment
			error(t, 'photo.savePhoto')
			break
		case 17101:
			// Save the attachment was successful
			success(t, 'photo.savePhoto')
			break

		default:
			// Unknown Code
			error(t, 'all.unknownCode', { code: code })
			break
	}
}

export default codeReader
