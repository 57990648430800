import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Context from '#context'

import LoadingScreen from '#comp/LoadingScreen'
import { LightHR } from '#comp/Custom/Headline'

/**
 * @class
 * @classdesc - Single row
 * @example
 * <InsideRow tenant={tenant} />
 */
class InsideRow extends Component {
	/**
	 * @typedef {Object} PropTypes
	 * @property {Object} tenant - The tenant object.
	 */
	static propTypes = {
		tenant: PropTypes.object.isRequired,
	}

	static defaultProps = {
		tenant: { attributes: {} },
	}

	/**
	 * Generates a formatted string for the second line of information based on tenant attributes.
	 *
	 * @param {Object} tenant - The tenant object containing configuration attributes.
	 * @returns {String} A formatted string for the second line of information.
	 */
	secondLine = (tenant) => {
		const { attributes } = tenant.configuration
		const parts = []

		if (!attributes) return ''

		const pushToString = (value, separator = ' ') => {
			if (value) {
				parts.push(value + separator)
			}
		}

		pushToString(attributes.market_id, ' - ')
		pushToString(attributes.address, ' - ')
		pushToString(attributes.zip)
		pushToString(attributes.city)

		if (attributes.spar_region) {
			parts.push('(' + attributes.spar_region + ' - ')
		} else if (attributes.market_type) {
			parts.push('(')
		}

		if (attributes.market_type) {
			parts.push(attributes.market_type?.name + ')')
		} else if (attributes.spar_region) {
			parts.push(')')
		}

		return parts.join('')
	}

	render() {
		const { tenant } = this.props

		return (
			<>
				<div className="py-2.5 truncate flex flex-col relative">
					<span className="truncate font-bold">{tenant.name}</span>
					<p className="italic text-sm w-full break-words whitespace-pre-wrap">
						{this.secondLine(tenant)}
					</p>
				</div>
				<LightHR />
			</>
		)
	}
}

/**
 * @class
 * @classdesc - Renders a list of tenants.
 * @example
 * <List onClick={onClock} loading={loading} />
 */
export default class List extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {Function} [onClick] - Onclick function
	 * @property {Boolean} [loading]
	 *
	 */
	static propTypes = {
		onClick: PropTypes.func,
		loading: PropTypes.bool,
	}
	static defaultProps = {
		onClick: null,
		loading: false,
	}

	render() {
		const { tenants, t } = this.context
		const { loading, onClick } = this.props

		if (loading) {
			return <LoadingScreen.Spinner className="mt-4" />
		}

		return (
			<div className="mt-1">
				{tenants?.map((tenant) => (
					<div key={tenant.id}>
						{onClick ? (
							<div
								data-tenantid={tenant.id}
								className="cursor-pointer"
								onClick={onClick.bind(this)}
							>
								<InsideRow tenant={tenant} />
							</div>
						) : (
							<NavLink to={tenant.id.toString()}>
								<InsideRow tenant={tenant} />
							</NavLink>
						)}
					</div>
				))}
				{tenants?.length === 0 && (
					<div className="w-full text-center mt-4 text-lg">
						{t('error.tenant.noTenants')}
					</div>
				)}
			</div>
		)
	}
}
