/**
 * Generates default headers for API requests.
 *
 * @param {String} bearer - The authorization token.
 * @param {String} method - The HTTP method (GET, POST, PUT, PATCH, etc.).
 * @param {Object} data - The data to be sent in the request body.
 * @param {String} contentType - The content type of the request.
 * @returns {Object} - The headers and options object for the API request.
 */
export const defaultHeader = (bearer, method, data, contentType) => {
	let myHeader = { headers: {} }

	if (bearer) {
		myHeader.headers.Authorization = bearer
	}

	if (method) {
		myHeader.method = method
	} else {
		myHeader.method = 'GET'
	}

	if (data && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
		if (contentType) {
			myHeader.headers['Content-Type'] = contentType
		} else {
			myHeader.headers['Content-Type'] = 'application/json'
		}
		myHeader.body = JSON.stringify(data)
	}

	return myHeader
}

/**
 * Performs an asynchronous API fetch.
 *
 * @param {String} url - The URL of the API endpoint.
 * @param {Object} options - The options object for the fetch request.
 * @param {Function} logout - The function to call in case of a logout.
 * @returns {Promise} - A promise that resolves to the API response.
 */
const apiFetch = async (url, options, logout) => {
	let status = null
	let logoutCheck = false

	try {
		const resp = await fetch(url, options)
		let respJson = null
		status = resp.status

		if (status !== 204) {
			respJson = await resp.json()
		}

		if (status === 401 && logout) {
			// Necessary to check and add to every request if the token is still valid.
			logoutCheck = true
			logout()
		}

		if (status !== 200 && status !== 201 && status !== 204) {
			throw respJson
		}

		return { ok: true, data: respJson }
	} catch (error) {
		return {
			ok: false,
			url: url,
			status: status,
			data: error,
			logout: logoutCheck,
		}
	}
}

export default apiFetch
